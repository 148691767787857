<template>
  <v-overlay :value="show">
    <v-card
      light
      class="pb-5"
      color="#333333"
      width="1300"
    >
      <v-card-title class="white--text d-flex justify-space-between">
        <span>Выбор номера ПТО для отправки </span>
        <v-btn
          color="error"
          small
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <div
        class="white pa-5 rounded-0"
        @click.stop
      >
        <v-row class="mb-2">
          <v-col>
            <label>
              Номер ПТО
              <!-- ref="identity_doc_name"
                v-model="filler_json.identity_doc_name"
                :items="identityDocumentsFiltered"
                :filter="filterBySearchField" -->
              <v-autocomplete
                v-model="selected_code"
                return-object
                auto-select-first
                :items="pointsFiltered"
                item-text="search"
                outlined
                background-color="white"
                dense
                hide-details="auto"
              />
            </label>
          </v-col>
        </v-row>
        <div class="d-flex justify-end">
          <v-btn
            class="mr-2"
            color="#5cb7b1"
            :disabled="!selected_code || selected_code === ''"
            @click="onSelect('signXml', selected_code)"
          >
            <!-- @click="onSelect('signXml')" -->
            Отправить
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-overlay>
</template>
<script>
import closeOnEscape from '@/mixins/close-on-escape.mixin.js'
import { mapGetters } from 'vuex'
import {waitAsyncDecision} from "@/helpers/async-component";
export default {
  mixins:[closeOnEscape],
  props:{
    show:{
      required:true,
      type:Boolean
    },
    params:{
      required:false,
      default: () => {},
      type:Object
    },
    selected: {
      required:false,
      type:Object,
      default:() => {}
    }
  },
  data(){
    return {
      selected_code: "",
      asyncDecision:{
        resolve:null,
        reject:null,
      }
    }
  },
  computed: {
    ...mapGetters({
      points:'catalogs/getNsiCustomsPoints'
    }),
    pointsFiltered(){
      return this.points.filter((i) => i.countryLetter === 'BY')
    }
  },
  watch: {
    selected: {
      handler() {
        if(this.selected && this.selected?.presented_documents.length){
          this.setPtoNum()
        }
      },
      deep: true,
    }
  },
  mounted(){
    if(this.selected && this.selected?.presented_documents.length){
      this.setPtoNum()
    }
  },
  methods:{
    waitAsyncDecision,
    setPtoNum(){
      const pto_code_doc = this.selected?.presented_documents.find((i) => i.doc_code === '09019')
      if(pto_code_doc){
        const pto_number = pto_code_doc.doc_number.split('/')[0]
        const search_pto_number = this.pointsFiltered.find((i) => i.code === pto_number)
        if(search_pto_number){
          this.selected_code = pto_number
        }
      }else{
        this.selected_code = ''
      }
    },
    onSelect(_, {code, name}){
      this.asyncDecision.resolve({code, name})
      this.$emit('update:show', false)
    },
    close(){
      this.asyncDecision.reject(false)
      this.$emit('update:show', false)
    }
  }
}
</script>
<style scoped>
.error-message{
    font-weight: bold;
    font-size: 18px;
}
</style>
